@media(max-width:1520px) {
    .left-svg {
        display: none;
    }
}

/* small css for the mobile nav close */
#nav-mobile-btn.close span:first-child {
    transform: rotate(45deg);
    top: 4px;
    position: relative;
    background: #a0aec0;
}

#nav-mobile-btn.close span:nth-child(2) {
    transform: rotate(-45deg);
    margin-top: 0px;
    background: #a0aec0;
}