  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

  html, body{
    font-family: 'Roboto', sans-serif;
  }

  .break-inside {
    -moz-column-break-inside: avoid;
    break-inside: avoid;
  }
  body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 100vh;
    line-height: 1.5;
  }
  
  [type='text'] {
    box-shadow: none !important;
  } 

  [type='password'] {
    box-shadow: none !important;
  } 
