@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .active-nav-link {
    @apply text-center text-indigo-400 items-center px-1 pt-1 border-b-4 border-indigo-400 text-sm font-normal leading-5 focus:outline-none focus:border-indigo-700 transition duration-150 ease-in-out bg-cyan-100 rounded drop-shadow-lg;
  }
  .inactive-nav-link {
    @apply text-center items-center px-1 pt-1 border-b-2 border-transparent text-sm font-normal leading-5 text-gray-700 hover:bg-gray-100  hover:border-gray-700 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out;
  }
  .active-side-link {
    @apply flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white bg-gray-100 dark:hover:bg-gray-700;
  }
  .inactive-side-link {
    @apply flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700;
  }
  .page-heading {
    @apply text-4xl font-medium text-custom-green  bg-clip-text bg-gradient-to-r from-pink-500 via-purple-500 to-pink-500;
  }
}


.text-custom-green {
  color: #0b6a3a;
}


textarea:focus {
  box-shadow: none;
}

.menuCss {
  display: flex;
  align-items: baseline;
  gap: 5px;
}

.dropdown-content {
  position: absolute;
  background-color: #fff;
  min-width: 125px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  margin-left: -95px;
  margin-top: 185px;
  padding-bottom: 10px;
  padding-right: 10px;
  border-radius: 5px;
}

.dropdown-content Button {
  float: none;
  /* color: black; */
  /* padding: 12px 16px; */
  text-decoration: none;
  display: block;
  text-align: left;
}
.dropdown-content a {
  float: none;
  color: black;
  /* padding: 12px 16px; */
  text-decoration: none;
  display: block;
  text-align: left;
}

.active-nav-link {
  color: #0b6a3a;
  background-color: transparent;
  text-decoration: none;
  border-bottom-width: 0px !important;
  font-weight: 700;
}

.menuItem {
  font-size: 18px;
}

@media screen and (min-width: 769px) {
  .mobMenuMain {
    display: block !important;
  }
}

@media screen and (max-width: 768px) {
  .mobMenu {
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    height: 100% !important;
    align-items: flex-start !important;
  }
  .mobMenuNav {
    display: flex !important;
    flex-wrap: nowrap !important;
    align-items: baseline !important;
    /* gap: 10px!important; */
    gap: 25px !important;
  }
  .mobMenuDiv {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    margin-left: inherit !important;
    margin-right: inherit !important;
  }
}

.dekstopNavbarParentDiv {
  width: 66% !important;
  margin: auto !important;
}

.full_width {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .mob_view_pb {
    padding-bottom: 15px;
    line-height: 1;
  }
}

@media only screen and (max-width: 1069px) {
  .topHeading {
    display: none !important;
  }
}

.mobMenuIcon {
  display: none !important;
}

@media only screen and (max-width: 1069px) {
  .mobMenuIcon {
    display: block !important;
  }
  .signOutMobBtn {
    display: block !important;
  }
  .mobMenuNav {
    display: flex;
    align-items: baseline;
    flex-wrap: nowrap;
    gap: 25px;
  }
}

.min-w-\[200px\] {
  min-width: 100% !important;
}

@media (min-width: 720px) {
  .md\:px-24 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
}

#canvas-container {
  /* height: 60vh; */
  /* height: 100%; */
  height: 400px;
  /* width: 60vw;
  position: relative; */
}

@media (max-width: 768px) {
  #canvas-container {
    /* height: auto; */
    /* height: 60vh; */
    /* height: 100%; */
    height: 400px;
    /* width: auto; */
  }
}
