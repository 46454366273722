/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 */

@media only screen and (max-width: 768px) {
    .accountDetailFormMainDiv {
        display: flex!important;
        flex-direction: column!important;
        align-items: center!important;
    }
    .accountDetailformMainDivFirstChild {
        width: 90%!important;
    }
    .accountDetailformMainDivSecondChild {
        width: 90%!important;
        padding-left: 0rem!important;
    }
    .accountDetailForm {
        display: flex !important;
        flex-wrap: wrap !important;
    }
    .accountDetailFormHeading {
        /* font-size: 30px !important; */
        font-size: 25px !important;
    }
    .connectMeFlex {
        display: flex!important;
        flex-wrap: wrap!important;
        flex-direction: column!important;
    }
    .connectMeFlexChild {
        width: 100%!important;
    }
    .connectMeFlexSecondChild {
        width: 100%!important;
        padding-left: 0rem!important;
    }
    .subserReqHeading {
        font-size: 30px!important;
    }
    .formParentDiv {
        display: flex!important;
        flex-wrap: wrap!important;
    }
}

@media only screen and (min-width: 768px) {
  .accSetSubHeading{
    font-size: 25px;
  }
}
.fa-twiiterx {
  background: url("https://xususa.com/wp-content/uploads/2023/11/tweet-logo-1.png");
  background-size: 22px;
  background-repeat: no-repeat;
  padding: 10.1px;
}