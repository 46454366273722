.up-image-div {
    max-height: 285px;
    /* border: 2px solid red; */
    overflow-y: scroll;
    padding: 10px;
}

.up-image-div-item {
    display: flex;
    gap: 10px;
}

/* .up-image-div-item-icon {} */

.up-image-div-item-name {
    flex: 1;
}

.up-image-div-item-action {
    color: red;
    font-weight: bold;
    cursor: pointer;
}