.menu-sec.menu-mobile {
  display: flex;
  flex-direction: column;
}

.menu-sec.menu-desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

/* @media screen and (max-width: 768px) {
  .menu-sec.menu-desktop {
    display: none !important;
  }

  .menu-sec.menu-mobile .menuItem {
    text-align: left;
  }
} */

@media screen and (max-width: 1069px) {
  .menu-sec.menu-desktop {
    display: none !important;
  }

  .menu-sec.menu-mobile .menuItem {
    text-align: left;
  }
}

.inactive-nav-link{
  border-bottom-width: 0px!important;
}



/* @media screen and (max-width: 1069px) {
  .menu-sec.menu-desktop {
    display: none !important;
  }
  .menu-sec.menu-mobile .menuItem {
    display: none!important;
    text-align: left;
  }
}
@media screen and (min-width: 1079px) {
  .menu-sec.menu-mobile .menuItem {
    display: block!important;
    text-align: left;
  }
} */